@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Document
 * ========================================================================== *//**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}/* Sections
 * ========================================================================== *//**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */h1 {
  font-size: 2em;
  margin: 0.67em 0;
}/* Grouping content
 * ========================================================================== *//**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}/**
 * Add the correct display in IE.
 */main {
  display: block;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/* Text-level semantics
 * ========================================================================== *//**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */b,
strong {
  font-weight: bolder;
}/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}/**
 * Add the correct font size in all browsers.
 */small {
  font-size: 80%;
}/* Embedded content
 * ========================================================================== *//**
 * Hide the overflow in IE.
 */svg:not(:root) {
  overflow: hidden;
}/* Forms
 * ========================================================================== *//**
 * Remove the margin on controls in Safari.
 */button,
input,
select {
  margin: 0;
}/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */button {
  overflow: visible; /* 1 */
  text-transform: none; /* 2 */
}/**
 * Correct the inability to style buttons in iOS and Safari.
 */button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}/**
 * Correct the padding in Firefox.
 */fieldset {
  padding: 0.35em 0.75em 0.625em;
}/**
 * Show the overflow in Edge 18- and IE.
 */input {
  overflow: visible;
}/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */legend {
  box-sizing: border-box; /* 1 */
  color: inherit; /* 2 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}/**
 * Remove the inheritance of text transform in Firefox.
 */select {
  text-transform: none;
}/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */textarea {
  margin: 0; /* 1 */
  overflow: auto; /* 2 */
}/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */::-webkit-search-decoration {
  -webkit-appearance: none;
}/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */::-moz-focus-inner {
  border-style: none;
  padding: 0;
}/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */:-moz-focusring {
  outline: 1px dotted ButtonText;
}/**
 * Remove the additional :invalid styles in Firefox.
 */:-moz-ui-invalid {
  box-shadow: none;
}/* Interactive
 * ========================================================================== *//*
 * Add the correct display in Edge 18- and IE.
 */details {
  display: block;
}/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}dialog:not([open]) {
  display: none;
}/*
 * Add the correct display in all browsers.
 */summary {
  display: list-item;
}/* Scripting
 * ========================================================================== *//**
 * Add the correct display in IE.
 */template {
  display: none;
}/* User interaction
 * ========================================================================== */body{font-family:"Roboto",sans-serif}a{text-decoration:none}a>button{cursor:pointer}p{color:#61676a}h1{font-family:"Roboto",sans-serif;font-weight:300;color:#0e305d;font-size:35px}@media (max-width: 1500px){h1{font-size:28px}}@media (max-width: 1200px){h1{font-size:26px}}h2{font-family:"Roboto",sans-serif;font-weight:300;color:#0e305d;font-size:30px}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type="number"]{-moz-appearance:textfield}

.headerbar_headerbar__9bfUM{margin-top:15px;color:#fff;border-top:2px solid #0e305d;border-bottom:2px solid #0e305d;padding:2px 0}.headerbar_inner__19mGL{background-color:#0e305d;padding:0 34px;display:flex;align-items:center;font-size:19px}.headerbar_item__37WqN{flex-grow:1}.headerbar_logo__26JIz{max-width:210px;position:relative;background-size:contain;height:59px;background-color:white;background-repeat:no-repeat;background-position:center}.headerbar_iconUser__1L_El{font-size:22px}.headerbar_userName__24NqD{margin-left:10px;font-size:19px}.headerbar_actionEntries__Rbt6t{float:right;display:flex;align-items:baseline}.headerbar_logoutButton__3VLPg{margin-left:20px}

.iconbutton_iconbutton__3rfub{background:none;color:white;border:none;font-size:20px;display:flex;padding:10px;border-radius:8px;position:relative;cursor:pointer;transition:all 0.3s}.iconbutton_iconbutton__3rfub .iconbutton_animation__2uDOm{position:absolute;top:0;right:0;bottom:0;left:0;background-color:white;border-radius:100}.iconbutton_iconbutton__3rfub .iconbutton_children__3IX6w{z-index:1;display:flex}.iconbutton_iconbutton__3rfub:hover{background-color:#ffffff15;transition:all 0.3s}.iconbutton_iconbutton__3rfub:focus{border:none;outline:none}

@media (min-width: 576px){.home_spacer__3MOp4{margin-top:60px}}.home_appWrapper__3Fes-{background-size:cover}.home_wrapper__2U3DQ{padding-top:40px;padding-bottom:40px;align-items:center}@media (max-width: 992px){.home_wrapper__2U3DQ{align-items:center}}.home_paper__2FPJi{margin-top:20px}.home_appContainer__39oOf{padding-left:40px}

.container_container__1x8Y8{padding:0 40px}@media (max-width: 576px){.container_container__1x8Y8{padding:0}}

.newsbox_newsbox__2lTaX{background-color:white;padding:40px}@media (max-width: 992px){.newsbox_newsbox__2lTaX{margin-bottom:40px}}.newsbox_divider__Zhvrf{border-top:1px solid #dddedf}.newsbox_text__3eetb{margin-top:30px;font-size:20px;color:#61676a;line-height:31px}

.appbutton_appButtonWrapper__2J3wj{max-width:100px}.appbutton_appButton__2jOlf{padding:10px;border-radius:20px;margin:0 auto;text-align:center}@media (max-width: 576px){.appbutton_appButton__2jOlf{max-width:50px}}.appbutton_default__1yQ9Y{transition:all 0.3s;background:#ffffff;background:linear-gradient(to bottom, #fff 0%, #eceded 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eceded',GradientType=0 )}.appbutton_default__1yQ9Y:hover{transition:all 0.3s;background:#e3e3e3;background:linear-gradient(to bottom, #fff 0%, #f2f2f2 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=0 )}.appbutton_internal__2WCod{transition:all 0.3s;background:#29557f;background:linear-gradient(to bottom, #29557f 0%, #002f5e 100%);filter:progid:DXImageTransform.Microsoft.gradient( startColorstr='#29557f', endColorstr='#002f5e',GradientType=0 )}.appbutton_internal__2WCod:hover{-webkit-animation-name:appbutton_gradient-animate__2H0jI;animation-name:appbutton_gradient-animate__2H0jI;-webkit-animation-duration:4s;animation-duration:4s}@-webkit-keyframes appbutton_gradient-animate__2H0jI{from{background-color:red}to{background-color:yellow}}@keyframes appbutton_gradient-animate__2H0jI{from{background-color:red}to{background-color:yellow}}.appbutton_appImage__31ncE{max-width:80px}@media (max-width: 576px){.appbutton_appImage__31ncE{max-width:50px}}.appbutton_appName__3OkGm{text-align:center;margin-top:15px;color:#3e4d61;font-weight:bold;font-size:14px}@media (max-width: 576px){.appbutton_appName__3OkGm{font-size:10px;max-width:60px;margin:10px auto 0}}

.appdashboard_groupTitle__y2OaU{font-size:20px;padding-left:20px;color:#61676a}.appdashboard_apps__jJsH0{display:flex;flex-flow:row wrap}.appdashboard_apps__jJsH0>div{margin:20px}@media (max-width: 992px){.appdashboard_apps__jJsH0{justify-content:space-between}}

.button_button__2pk5U{border:none;background-color:#0e305d;color:#fff;padding:15px;font-size:18px;cursor:pointer;position:relative;overflow:hidden;transition:all 0.3s}.button_button__2pk5U:hover{background-color:#28547f;transition:all 0.3s}.button_button__2pk5U:focus{outline:none}.button_fullWidth__1lIBo{width:100%}.button_children__B4Ndf{z-index:1}.button_animation__1nf_l{position:absolute;top:0;right:0;bottom:0;left:0;background-color:rgba(255,255,255,0.4)}.button_animation__1nf_l:focus{outline:none}

.loginbox_loginbox__1mAtr{padding:40px;background-color:white;width:500px;max-width:100%;margin-top:-200px}@media (max-width: 768px){.loginbox_loginbox__1mAtr{width:100%;margin-left:-11%}}@media (max-width: 576px){.loginbox_loginbox__1mAtr{display:table-cell}}.loginbox_loginbox__1mAtr label{color:#0e305d;font-weight:bold}.loginbox_formField__3F7pD{width:94%}.loginbox_loginButton__3U6SV{text-transform:uppercase;font-weight:bold}.loginbox_error__94gYN{color:red}

.input_inputComponent__ZZuDq{padding:15px;font-size:18px;color:#61676a;border:1px solid #d9d9d9}.input_inputComponent__ZZuDq:focus{border:1px solid #0e305d}

.login_loginWrapper__3oB_T{height:89vh;margin-top:5px;background-size:cover}@media (min-width: 768px){.login_loginWrapper__3oB_T{padding:40px}}.login_loginBoxWrapper__39sTy{display:flex;justify-content:center;align-items:center;height:100%}

