@import "../../theme/theme";

.loginWrapper {
  height: 89vh; // TODO: calc this
  margin-top: 5px;
  background-size: cover;

  @media (min-width: $MD) {
    padding: $padding-page;
  }
}

.loginBoxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
