@import "../../../theme/theme.scss";

.newsbox {
  background-color: white;
  padding: 40px;

  @media (max-width: $LG) {
    margin-bottom: 40px;
  }
}

.divider {
  border-top: 1px solid $color-grey;
}

.text {
  margin-top: 30px;
  font-size: 20px;
  color: $color-text-grey;
  line-height: 31px;
}
