@import "../../../../theme/theme.scss";

$logo-height: 59px;
$border-headerbar: 2px;

.headerbar {
  margin-top: 15px;
  color: $color-white;
  border-top: $border-headerbar solid $color-primary;
  border-bottom: $border-headerbar solid $color-primary;
  padding: 2px 0;
}

.inner {
  background-color: $color-primary;
  padding: 0 ($padding-page - 6px);
  display: flex;
  align-items: center;
  font-size: $font-size-headerbar;
}

.item {
  flex-grow: 1;
}

.logo {
  max-width: 210px;
  position: relative;
  background-size: contain;
  height: $logo-height;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;

  /*   &:before,
  &:after {
    content: " ";
    background-color: $color-white;
    height: $logo-height + ($border-headerbar * 2) + ($border-headerbar * 2);
    width: 7px;
    position: absolute;
    margin-top: -$border-headerbar * 2;
  }

  &:after {
    right: 0px;
  } */
}

.iconUser {
  font-size: 22px;
}

.userName {
  margin-left: 10px;
  font-size: 19px;
}

.actionEntries {
  float: right;
  display: flex;
  align-items: baseline;
}

.logoutButton {
  margin-left: 20px;
}
