@import "../../../../theme/theme.scss";

.appButtonWrapper {
  max-width: 100px;
}

.appButton {
  padding: 10px;
  border-radius: 20px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: $SM) {
    max-width: 50px;
  }
}

.default {
  @include transition-default;
  @include gradient-light;

  &:hover {
    @include transition-default;
    @include gradient-light-hover;
  }
}
.internal {
  @include transition-default;
  @include gradient-primary;

  &:hover {
    @include gradient-primary-animated;
  }
}

.appImage {
  max-width: 80px;

  @media (max-width: $SM) {
    max-width: 50px;
  }
}

.appName {
  text-align: center;
  margin-top: 15px;
  color: #3e4d61;
  font-weight: bold;
  font-size: 14px;

  @media (max-width: $SM) {
    font-size: 10px;
    max-width: 60px;
    margin: 10px auto 0;
  }
}
