@import "../../../theme/theme";

.loginbox {
  padding: 40px;
  background-color: white;
  width: 500px;
  max-width: 100%;
  margin-top: -200px;

  @media (max-width: $MD) {
    width: 100%;
    margin-left: -11%;
  }

  @media (max-width: $SM) {
    display: table-cell;
  }

  label {
    color: $color-primary;
    font-weight: bold;
  }
}

.formField {
  width: 94%;
}

.loginButton {
  text-transform: uppercase;
  font-weight: bold;
}

.error {
  color: $color-error;
}
