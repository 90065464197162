@import "../../../theme/theme.scss";

.button {
  border: none;
  background-color: $color-primary;
  color: $color-white;
  padding: 15px;
  font-size: 18px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  @include transition-default;

  &:hover {
    background-color: $color-secondary;
    @include transition-default;
  }

  &:focus {
    outline: none;
  }
}

.fullWidth {
  width: 100%;
}

.children {
  z-index: 1;
}

.animation {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #ffffff, $alpha: 0.4);

  &:focus {
    outline: none;
  }
}
