@import "../../theme/theme.scss";

$wrapper-padding: 40px;

.spacer {
  @media (min-width: $SM) {
    margin-top: 60px;
  }
}

.appWrapper {
  background-size: cover;
}

.wrapper {
  padding-top: $wrapper-padding;
  padding-bottom: $wrapper-padding;
  align-items: center;

  @media (max-width: $LG) {
    /* flex-direction: column-reverse !important; */
    align-items: center;
  }
}

.paper {
  margin-top: 20px;
}

.appContainer {
  padding-left: 40px;
}
