@import "../../../theme/theme.scss";

.groupTitle {
  font-size: 20px;
  padding-left: 20px;
  color: $color-text-grey;
}

.apps {
  display: flex;
  flex-flow: row wrap;

  > div {
    margin: 20px;
  }

  @media (max-width: $LG) {
    justify-content: space-between;
  }
}
