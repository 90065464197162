@import "../../../theme/theme.scss";

.inputComponent {
  padding: 15px;
  font-size: 18px;
  color: $color-text-grey;
  border: 1px solid $color-border;

  &:focus {
    border: 1px solid $color-primary;
  }
}
