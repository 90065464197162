@mixin gradient-primary {
  background: #29557f; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #29557f 0%,
    #002f5e 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #29557f 0%,
    #002f5e 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #29557f 0%,
    #002f5e 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29557f', endColorstr='#002f5e',GradientType=0 ); /* IE6-9 */
}

@mixin gradient-primary-hover {
  background: #29557f; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #29557f 0%,
    #013971 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #29557f 0%,
    #002f5e 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #376ea0 0%,
    #002f5e 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#29557f', endColorstr='#002f5e',GradientType=0 ); /* IE6-9 */
}

@mixin gradient-light {
  background: #ffffff; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #eceded 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #eceded 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #eceded 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eceded',GradientType=0 ); /* IE6-9 */
}

@mixin gradient-light-hover {
  background: #e3e3e3; /* Old browsers */
  background: -moz-linear-gradient(
    top,
    #ffffff 0%,
    #f2f2f2 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #f2f2f2 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #f2f2f2 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=0 ); /* IE6-9 */
}

@mixin gradient-primary-animated {
  @keyframes gradient-animate {
    from {
      background-color: red;
    }
    to {
      background-color: yellow;
    }
  }

  animation-name: gradient-animate;
  animation-duration: 4s;
}
