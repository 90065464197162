@import "./theme/theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap");

@import-normalize;

body {
  font-family: $font-primary;
}

a {
  text-decoration: none;

  > button {
    cursor: pointer;
  }
}

p {
  color: $color-text-grey;
}

h1 {
  @include main-heading-style;
  font-size: 35px;

  @media (max-width: 1500px) {
    font-size: 28px;
  }

  @media (max-width: $XL) {
    font-size: 26px;
  }
}

h2 {
  @include main-heading-style;
  font-size: 30px;
}

// disable number arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
