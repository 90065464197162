@import "./gradient";
@import "./transition";
@import "./breakpoints";

$color-primary: #0e305d;
$color-secondary: #28547f;
$color-text-grey: #61676a;
$color-white: #fff;
$color-grey: #dddedf;
$color-border: #d9d9d9;
$color-error: red;

$padding-page: 40px;
$font-size-headerbar: 19px;

$font-primary: "Roboto", sans-serif;

@mixin main-heading-style {
  font-family: $font-primary;
  font-weight: 300;
  color: $color-primary;
}
