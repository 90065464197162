@import "../../../theme/theme.scss";

.iconbutton {
  background: none;
  color: white;
  border: none;
  font-size: 20px;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  @include transition-default;

  .animation {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
    border-radius: 100;
  }

  .children {
    z-index: 1;
    display: flex;
  }

  &:hover {
    background-color: #ffffff15;
    @include transition-default;
  }

  &:focus {
    border: none;
    outline: none;
  }
}
